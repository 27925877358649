import { $ } from '../vendors/jquery';

const BlogLoadMore = {
    button: $('[data-blog-grid="load_more"]').find('button'),
    blogFilter: $('.blog_search'),
    blogFilterResults: $('.blog_posts_grid .blog-list'),
    loadMoreWrapper: $('.blog_posts_grid .load-more-wrapper'),
    // page: 2,

    loadMore( catPage = 2 ) {
        const tags = BlogLoadMore.blogFilter.attr('data-tags');
        const search = BlogLoadMore.blogFilter.attr('data-search');
        const category = BlogLoadMore.blogFilter.attr('data-category');
        const perPage = parseInt(BlogLoadMore.blogFilterResults.attr('data-per-page'));
        const postType = BlogLoadMore.blogFilterResults.attr('data-post_type');

        /*
        let page = '';

        if (BlogLoadMore.blogFilter.attr('data-page')) {
            page = BlogLoadMore.blogFilter.attr('data-page');
        } else {
            page = BlogLoadMore.blogFilterResults.attr('data-page');
        }

        page = parseInt(page) + 1;

        if (BlogLoadMore.blogFilter.attr('data-page')) {
            // BlogLoadMore.blogFilter.attr('data-page');
            BlogLoadMore.blogFilter.attr('data-page', page);
        } else {
            // page = BlogLoadMore.blogFilterResults.attr('data-page');
            BlogLoadMore.blogFilterResults.attr('data-page', page);
        }

        
        console.log(page);
        */

        const dataLoadMore = {
            // page: BlogLoadMore.page,
            page: catPage,
            action: 'alif_blog_loadmore',
            per_page: perPage,
            post_type: postType,
        };

        if (tags) {
            dataLoadMore.tags = tags;
        }

        if (category) {
            dataLoadMore.category = category;
        } else {
            dataLoadMore.category = 'all';
        }

        if (search) {
            dataLoadMore.search = search;
        }

        console.log('testing1');
        console.log(dataLoadMore);

        $.ajax({
            url: alifteam.ajax.url,
            type: 'POST',
            data: dataLoadMore,
            cache: false,
            beforeSend: () => {
                BlogLoadMore.button.show();
                BlogLoadMore.loadMoreWrapper.addClass('loading');
                BlogLoadMore.loadMoreWrapper.removeClass('no-results hidden');
            },
            success: (response) => {

                //hide button if no results
                if (!response.html) {
                    BlogLoadMore.loadMoreWrapper.addClass('no-results');
                }

                //hide button if no more posts
                if (response.hideButton) {
                    BlogLoadMore.button.hide();
                }

                //append after last post
                BlogLoadMore.blogFilterResults.append(response.html);
                BlogLoadMore.loadMoreWrapper.removeClass('loading');
                // BlogLoadMore.page++;
                catPage++;
            },
            error: (error) => {
                console.log(error);
                BlogLoadMore.loadMoreWrapper.removeClass('loading');
            }
        });
    },

    clearList() {
        BlogLoadMore.blogFilter.attr('data-page', 0);
        BlogLoadMore.blogFilterResults.html('');
    },

    init() {
        BlogLoadMore.button.on('click', () => {
            BlogLoadMore.loadMore();
        });
    }
};

export default BlogLoadMore;
