import { $ } from '../vendors/jquery';
import BlogLoadMore from './blog-load-more';

const BlogFilter = {
    filter: $('.blog_search'),
    searchInput: $('.blog_search_content #blog_search'),
    catSelect: $('.blog_search').find('#blog_categories'),
    paged: 1,

    setupSearch() {
        BlogFilter.searchInput.on('keyup keypress change', function () {
            const searchTerm = $(this).val();
            let debounceTimer;
            BlogFilter.filter.attr('data-search', searchTerm);

            if ((searchTerm.length >= 3 || 0 === searchTerm.length) && false === $('.load-more-wrapper').hasClass('loading')) {
                $('.load-more-wrapper').addClass('loading');
                clearTimeout(debounceTimer);
                BlogLoadMore.clearList();
                debounceTimer = setTimeout(function () {
                    BlogFilter.triggerFilter();
                }, 3500);
            }
            
        });
    },
    setupCats() {

        //add select2
        if (BlogFilter.catSelect.length) {
            BlogFilter.catSelect.select2({
                placeholder: 'Select...',
                minimumResultsForSearch: -1
            });
        }

        BlogFilter.catSelect.on('select2:select', function (e) {
            const selectedData = e.params.data;
            BlogFilter.filter.attr('data-category', selectedData.id);
            //trigger filter
            BlogFilter.triggerFilter();
        });
    },
    checkCatParam(getCategory) {
        // Get all the option elements from the category select
        const categoryOptions = BlogFilter.catSelect.find('option');
        console.log(getCategory.trim());
        // Find the option that matches the category name
        let matchedValue = null;
        categoryOptions.each(function () {
            console.log($(this).attr('name'));
            if ($(this).attr('name') === getCategory.trim()) {
                matchedValue = $(this).val();

                return false; // Break out of the loop
            }
        });
    
        // If a matching value was found, set it as the selected value
        if (matchedValue) {
            BlogFilter.catSelect.val(matchedValue).trigger('change');
        } else {
            // Fallback to the default value if no match is found
            BlogFilter.catSelect.val('0').trigger('change');
        }
    
        // Trigger the select2:select event
        BlogFilter.catSelect.trigger('select2:select');
    },
    triggerFilter() {
        BlogLoadMore.clearList();
        BlogLoadMore.loadMore(BlogFilter.paged);
    },
    init() {
        this.setupSearch();
        this.setupCats();

        const urlParams = new URLSearchParams(window.location.search);
        const getCategory = urlParams.get('cat');

        if (getCategory) {
            this.checkCatParam(getCategory);
        }
    }
};

export default BlogFilter;
